<template>
    <section>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">
                            La page est en cours de construction
                        </div>
                        <b-img src="https://sites.comolapapaya.com/img/construction-60f96342a8efe.jpg" fluid alt="Page en cours de construction"></b-img>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'construction'
}
</script>

<style scoped>
.card{
    background: #952f3d
}
.card-title{
    color: white;
}
</style>